<template>
  <div id="app">
    <transition-page>
      <router-view v-on:nav-back="navBack" v-on:hide-imprint="hideImprint"/>
    </transition-page>
    <navigation :back-route="backRoute" :imprint-route="imprintRoute"/>
  </div>
</template>

<style lang="scss">
@font-face {
  font-family: 'pixelate';
  src: url(assets/fonts/sfomi/MonkeyIsland-1991-refined.ttf) format('truetype');
}

@font-face {
  font-family: 'commodore';
  src: url(assets/fonts/Commodore-Pixelized-v1.2.ttf) format('truetype');
}

@import 'assets/scss/app';
</style>

<script>
import TransitionPage from "@/components/partial/TransitionPage";
import Navigation from "@/components/Navigation";

export default {
  name: 'App',
  components: {Navigation, TransitionPage},
  data() {
    return {
      backRoute: null,
      imprintRoute: '/imprint',
    }
  },
  methods: {
    navBack: function (event) {
      // `this` inside methods points to the Vue instance
      this.backRoute = event;
    },
    hideImprint: function (event) {
      // `this` inside methods points to the Vue instance
      this.imprintRoute = event;
    }
  }
}
</script>