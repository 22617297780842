<template>
  <frame-page>
    <div id="df-desk-image" class="df-image"/>
    <div class="df-content">
      <canvas id="df-desk-stars" width="29" height="35"/>
      <router-link to="/master" class="df-shadow-link df-highlight" id="df-desk-master">
        look at master's degree
      </router-link>
      <router-link to="/projects" class="df-shadow-link df-highlight" id="df-desk-writer">
        go to projects page
      </router-link>
      <router-link to="/contact" class="df-shadow-link df-highlight" id="df-desk-amiga">
        go to contact page
      </router-link>
      <router-link to="/" class="df-shadow-link df-highlight" id="df-desk-canvas">
        go back to intro
      </router-link>
    </div>
  </frame-page>
</template>

<style lang="scss">
@import '../assets/scss/desk';
</style>

<script>
import FramePage from "@/components/FramePage";
import {StarsAnimation} from '@/assets/js/desk/stars';

let stars;
let highlightInterval;

export default {
  name: 'DeskScreen',
  components: {FramePage},
  title: 'Home',
  mounted() {
    this.$emit('nav-back', null);
    this.$emit('hide-imprint', '/imprint');
    stars = new StarsAnimation();
    stars.animate();
    // const highlights = document.getElementsByClassName('df-highlight');

    // highlightInterval = setInterval(function () {
    //   highlights.forEach(item => item.classList.toggle('show'));
    //   setTimeout(() => {
    //     highlights.forEach(item => item.classList.toggle('show'));
    //   }, 500);
    // }, 10000)
  },
  destroyed() {
    clearInterval(highlightInterval);
  }
}
</script>
