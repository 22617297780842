export class Window {
  constructor(elmnt) {
    this.elmnt = elmnt;
    this.pos1 = 0;
    this.pos2 = 0;
    this.pos3 = 0;
    this.pos4 = 0;
    this.prevPixelLeft = 0.0;
    this.prevPixelTop = 0.0;
    this.elmntOffsetLeft = elmnt.offsetLeft;
    this.elmntOffsetTop = elmnt.offsetTop;
    this.monitorPixelWidth = 176;
    this.monitorPixelHeight = 131;
    this.pixelLeftLimit = 43;
    this.pixelTopLimit = 57;
    this.border = this.getMonitorOffset();
    this.pixelWidth = this.border[0] / this.monitorPixelWidth;
    this.pixelHeight = this.border[1] / this.monitorPixelHeight;
  }

  resizeHandler = () => {
    this.elmnt = document.getElementById('df-window');
    if (!this.elmnt.classList.contains('df-window-large')) {
      this.elmnt.style.left = null;
      this.elmnt.style.top = null;
    } else {
      this.elmnt.style.left = '0';
      this.elmnt.style.top = '0';
    }
    this.pos1 = 0;
    this.pos2 = 0;
    this.pos3 = 0;
    this.pos4 = 0;
    this.prevPixelLeft = 0.0;
    this.prevPixelTop = 0.0;
    this.elmntOffsetLeft = this.elmnt.offsetLeft;
    this.elmntOffsetTop = this.elmnt.offsetTop;
    this.elmntOffsetLeft = this.elmnt.offsetLeft;
    this.elmntOffsetTop = this.elmnt.offsetTop;
    this.border = this.getMonitorOffset();
    this.pixelWidth = this.border[0] / this.monitorPixelWidth;
    this.pixelHeight = this.border[1] / this.monitorPixelHeight;
  }

  getMonitorOffset() {
    let monitor = document.getElementById('df-stars');
    return [monitor.offsetWidth, monitor.offsetHeight];
  }

  mousedownHandler = event => {
    if (undefined === event.changedTouches) {
      event.preventDefault();
    }
    if (!this.elmnt.classList.contains('df-window-large')) {
      // get the mouse cursor position at startup:
      this.pos3 = event.changedTouches ? event.changedTouches[0].screenX : event.clientX;
      this.pos4 = event.changedTouches ? event.changedTouches[0].screenY : event.clientY;
      this.elmntOffsetLeft = this.elmnt.offsetLeft;
      this.elmntOffsetTop = this.elmnt.offsetTop;
      document.onmouseup = this.closeDragElement;
      document.ontouchend = this.closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = this.elementDrag;
      document.ontouchmove = this.elementDrag;
    }
  }

  elementDrag = e => {
    if (undefined === e.changedTouches) {
      e.preventDefault();
    }
    // calculate the new cursor position:
    let clientX = e.changedTouches ? e.changedTouches[0].screenX : e.clientX;
    let clientY = e.changedTouches ? e.changedTouches[0].screenY : e.clientY;
    this.pos1 = this.pos3 - clientX;
    this.pos2 = this.pos4 - clientY;
    // set the element's new position:
    let currentOffsetLeft = Math.round((this.elmntOffsetLeft - this.pos1) / this.pixelWidth);
    let currentOffsetTop = Math.round((this.elmntOffsetTop - this.pos2) / this.pixelHeight);
    currentOffsetLeft = currentOffsetLeft < 0 ? 0 : currentOffsetLeft;
    currentOffsetTop = currentOffsetTop < 0 ? 0 : currentOffsetTop;
    currentOffsetLeft = currentOffsetLeft > this.pixelLeftLimit ? this.pixelLeftLimit : currentOffsetLeft;
    currentOffsetTop = currentOffsetTop > this.pixelTopLimit ? this.pixelTopLimit : currentOffsetTop;
    let styleLeft = currentOffsetLeft * this.pixelWidth;
    let styleTop = currentOffsetTop * this.pixelHeight;
    if (currentOffsetLeft !== this.prevPixelLeft || currentOffsetTop !== this.prevPixelTop) {
      this.prevPixelLeft = currentOffsetLeft;
      this.prevPixelTop = currentOffsetTop;
      this.elmnt.style.left = styleLeft + 'px';
      this.elmnt.style.top = styleTop + 'px';
    }
  }

  closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.ontouchend = null;
    document.onmousemove = null;
    document.ontouchmove = null;
  }
}

export function resizeElement(elmnt, handle) {
  handle.onmouseup = toggleResize;
  handle.ontouchend = toggleResize;

  function toggleResize() {
    elmnt.style.left = null;
    elmnt.style.top = null;
    elmnt.classList.toggle('df-window-large');
  }
}

export function removeResize(handle) {
  handle.onmouseup = null;
  handle.ontouchend = null;
}