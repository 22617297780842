<template>
  <div id="df-paper">
    <h1 id="df-project-title" class="df-shadow-text">WORK</h1>
    <div  id="df-work-image" class="df-image"/>
    <SueHayward/>
    <StudioKubik/>
    <h1>OTHER PROJECTS</h1>
    <OtherProjects/>
  </div>
</template>

<style lang="scss">
@import '../../assets/scss/project';
</style>

<script>
import StudioKubik from "@/components/projects/StudioKubik";
import SueHayward from "@/components/projects/SueHayward";
import OtherProjects from "@/components/projects/OtherProjects";

export default {
  name: 'ProjectList',
  components: {OtherProjects, SueHayward, StudioKubik},
  mounted() {
  },
  destroyed() {
  }
}
</script>
