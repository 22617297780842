<template>
  <frame-page>
    <div id="df-master-background" class="df-image"/>
    <div class="df-content">
      <div class="df-master-content">
        <p class="small-caps">
          Free University Berlin<br>
          Dep. of Mathematics and Computer Science
        </p>
        <h1 class="h2 small-caps">Degree Certificate</h1>
        <p class="h3 highlight">
          Mr Maximilian Zumbansen
        </p>
        <p>
          born in Berlin on 06 April 1989
        </p>
        <p>
          has successfully completed the <br>
          Master's Degree Programme in
        </p>
        <p class="h3 highlight">Mathematics</p>
        <p>
          In accordance with the university <br>
          examination regulations of 25 April 2018
        </p>
        <p>the Degree of</p>
        <p class="h3 highlight">Master of Science (M.Sc.)</p>
        <p>
          is hereby awarded.
        </p>
        <p class="text-left">
          <br><br>
          Berlin, 21 July 2020
        </p>
      </div>
    </div>
  </frame-page>
</template>

<style lang="scss">
@import '../assets/scss/master';
</style>

<script>
import FramePage from '@/components/FramePage';

export default {
  name: 'MasterScreen',
  components: {FramePage},
  title: 'Masters Degree',
  mounted() {
    this.$emit('nav-back', '/home');
  }
}
</script>
