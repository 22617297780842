<template>
  <frame-page>
    <div id="df-monitor" class="df-image"/>
    <div class="df-amiga-content">
      <canvas id="df-stars" width="176" height="131"/>
      <div id="df-window">
        <div id="df-window-handle" class="df-window-bar">
          <div id="df-window-resize" class="df-window-resizer"/>
        </div>
        <div class="df-window-content">
          <h1>Contact me!</h1>
          <p>
            Send an email to <a :href="mailto"><span>max</span><span>[at]</span><span>deynfisk.de</span></a>.
            The form below is not yet functional. Stay tuned!
          </p>
          <label>
            From<br>
            <input v-model="mail_address" placeholder="enter mail..">
          </label>
          <br><br>
          <label>
            Subject<br>
            <input v-model="mail_subject" placeholder="enter subject..">
          </label>
          <br><br>
          <label>
            Body<br>
            <textarea v-model="mail_body" placeholder="enter body content.."/>
          </label>
          <br><br>
          <button>send</button>
          <br>
        </div>
        <div id="df-window-back" class="df-image"/>
        <div id="df-window-front" class="df-image"/>
        <div id="df-window-back-large" class="df-image"/>
        <div id="df-window-front-large" class="df-image"/>
      </div>
    </div>
    <div id="df-amiga-hull-outer" class="df-image"/>
  </frame-page>
</template>

<style lang="scss">
@import '../assets/scss/amiga';
</style>

<script>
import {Stars} from "@/assets/js/amiga/stars";
import {Window, resizeElement, removeResize} from "@/assets/js/amiga/window";
import FramePage from "@/components/FramePage";

let stars;
let contactWindow;

export default {
  name: 'AmigaScreen',
  components: {FramePage},
  title: 'Contact',
  data() {
    return {
      mail_address: '',
      mail_subject: '',
      mail_body: '',
      mailto: ''
    }
  },
  mounted() {
    this.$emit('nav-back', '/home');
    stars = new Stars();
    stars.starField();
    stars.run();
    document.addEventListener('mousemove', stars.mousemoveHandler);
    document.addEventListener('touchmove', stars.mousemoveHandler);

    contactWindow = new Window(document.getElementById('df-window'))
    document.getElementById('df-window-handle').addEventListener('mousedown', contactWindow.mousedownHandler);
    document.getElementById('df-window-handle').addEventListener('touchstart', contactWindow.mousedownHandler);
    window.addEventListener('resize', contactWindow.resizeHandler);
    resizeElement(
      document.getElementById('df-window'),
      document.getElementById('df-window-resize')
    );

    // Email obfuscator script 2.1 by Tim Williams, University of Arizona
    // Random encryption key feature coded by Andrew Moulden
    // This code is freeware provided these four comment lines remain intact
    // A wizard to generate this code is at http://www.jottings.com/obfuscator/
    let coded = 'Xin@H1kKIE4W.H1';
    let key = 'iIc3wALgd1xRVBzEmjY7yubHon0UvSZXJN6kGqFlKOCsM5taf2WTPr8Qeh4Dp9';
    let shift = coded.length;
    let ltr = '';
    this.mailto = 'mailto:';
    for (let i = 0; i < coded.length; i++) {
      if (-1 === key.indexOf(coded.charAt(i))) {
        ltr = coded.charAt(i);
        this.mailto += (ltr);
        continue;
      }
      ltr = (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length;
      this.mailto += (key.charAt(ltr));
    }
  },
  destroyed() {
    document.removeEventListener('mousemove', stars.mousemoveHandler);
    document.removeEventListener('touchmove', stars.mousemoveHandler);
    window.removeEventListener('resize', contactWindow.resizeHandler);
    document.getElementById('df-window-handle').removeEventListener('mousedown', contactWindow.mousedownHandler);
    document.getElementById('df-window-handle').removeEventListener('touchstart', contactWindow.mousedownHandler);
    removeResize(document.getElementById('df-window-resize'));
  }
}
</script>
