<template>
  <a :href="link" target="_blank">
    <span><slot/></span><span class="df-icon df-icon-arrow large"/>
  </a>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: ['link']
}
</script>