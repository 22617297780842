<template>
  <router-link :to="to" :id="id" :class="classes">
    <div class="df-nav-icon"/>
    <div class="df-nav-glow"/>
    <slot/>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationIconLink',
  props: ['to', 'id', 'classes']
}
</script>