const scenes = [
    {
        monthIdx: 0,
        month: '05May',
        scpt: 'MAYCLRSCPT',
        name: 'V08',
        title: 'May - Jungle Waterfall - Clear',
        maxVolume: 0
    },
    {
        month: '05May',
        scpt: 'MAYCLDYSCPT',
        name: 'V08CLDY',
        title: 'May - Jungle Waterfall - Cloudy',
        maxVolume: 0
    },
    {
        month: '05May',
        scpt: 'MAYRAINSCPT',
        name: 'V08RAIN',
        title: 'May - Jungle Waterfall - Rain',
        maxVolume: 0
    },
];

function _var_exists(name) {
    // return true if let exists in "global" context, false otherwise
    try {
        eval('let foo = ' + name + ';');
    } catch (e) {
        return false;
    }
    return true;
}

let Namespace = {
    // simple namespace support for classes
    create: function (path) {
        // create namespace for class
        let container = null;
        while (path.match(/^(\w+)\.?/)) {
            let key = RegExp.$1;
            path = path.replace(/^(\w+)\.?/, "");

            if (!container) {
                if (!_var_exists(key)) eval('window.' + key + ' = {};');
                eval('container = ' + key + ';');
            } else {
                if (!container[key]) container[key] = {};
                container = container[key];
            }
        }
    },
    prep: function (name) {
        // prep namespace for new class
        if (name.match(/^(.+)\.(\w+)$/)) {
            let path = RegExp.$1;
            name = RegExp.$2;
            Namespace.create(path);
        }
        return {name: name};
    }
};

function $(thingy) {
    // universal DOM lookup function, extends object with hide/show/addClass/removeClass
    // can pass in ID or actual DOM object reference
    let obj = (typeof (thingy) == 'string') ? document.getElementById(thingy) : thingy;
    if (obj && !obj.setOpacity) {
        obj.hide = function () {
            this.style.display = 'none';
            return this;
        };
        obj.show = function () {
            this.style.display = '';
            return this;
        };
        obj.addClass = function (name) {
            this.removeClass(name);
            this.className += ' ' + name;
            return this;
        };

        obj.removeClass = function (name) {
            let classes = this.className.split(/\s+/);
            let idx = find_idx_in_array(classes, name);
            if (idx > -1) {
                classes.splice(idx, 1);
                this.className = classes.join(' ');
            }
            return this;
        };

        obj.setClass = function (name, enabled) {
            if (enabled) this.addClass(name);
            else this.removeClass(name);
        };
    }
    return obj;
}

function find_idx_in_array(arr, elem) {
    // return idx of elem in arr, or -1 if not found
    for (let idx = 0, len = arr.length; idx < len; idx++) {
        if (arr[idx] === elem) return idx;
    }
    return -1;
}

class Color {
    constructor(r = 0, g = 0, b = 0) {
        this.red = 0;
        this.green = 0;
        this.blue = 0;

        this.set(r, g, b);
    }

    set(r, g, b) {
        this.red = r;
        this.green = g;
        this.blue = b;
    }
}

class Cycle {
    constructor(r, rev, l, h) {
        this.rate = r;
        this.reverse = rev;
        this.low = l;
        this.high = h;
    }
}

class Palette {
    static PRECISION = 100;
    // static USE_BLEND_SHIFT = 1;
    static CYCLE_SPEED = 280;
    static ENABLE_CYCLING = 1;

    constructor(clrs, cycls) {
        this.colors = null;
        this.baseColors = null;
        this.cycles = null;
        this.numColors = 0;
        this.numCycles = 0;

        // class constructor
        this.colors = [];
        this.baseColors = [];
        for (let idx = 0, len = clrs.length; idx < len; idx++) {
            const clr = clrs[idx];
            this.baseColors.push(new Color(clr[0], clr[1], clr[2]));
        }

        this.cycles = [];
        for (let idx = 0, len = cycls.length; idx < len; idx++) {
            const cyc = cycls[idx];
            this.cycles.push(new Cycle(cyc.rate, cyc.reverse, cyc.low, cyc.high));
        }

        this.numColors = this.baseColors.length;
        this.numCycles = this.cycles.length;
    }

    static DFLOAT_MOD(a, b) {
        return (Math.floor(a * Palette.PRECISION) % Math.floor(b * Palette.PRECISION)) / Palette.PRECISION;
    }

    importColors(source) {
        // import colors into our base color list
        let dest = this.baseColors;
        this.copyColors(source, dest);
    }

    copyColors(source, dest) {
        // copy one array of colors to another
        for (let idx = 0, len = source.length; idx < len; idx++) {
            if (!dest[idx]) dest[idx] = new Color();
            dest[idx].red = source[idx].red;
            dest[idx].green = source[idx].green;
            dest[idx].blue = source[idx].blue;
        }
    }

    swapColors(a, b) {
        // swap the color values of a with b
        let temp;
        temp = a.red;
        a.red = b.red;
        b.red = temp;
        temp = a.green;
        a.green = b.green;
        b.green = temp;
        temp = a.blue;
        a.blue = b.blue;
        b.blue = temp;
    }

    reverseColors(colors, range) {
        // reverse order of colors
        let i;
        let cycleSize = (range.high - range.low) + 1;

        for (i = 0; i < cycleSize / 2; i++)
            this.swapColors(colors[range.low + i], colors[range.high - i]);
    }

    fadeColor(sourceColor, destColor, frame, max) {
        // fade one color into another by a partial amount, return new color in between
        let tempColor = new Color();

        if (!max) return sourceColor; // avoid divide by zero
        if (frame < 0) frame = 0;
        if (frame > max) frame = max;

        tempColor.red = Math.floor(sourceColor.red + (((destColor.red - sourceColor.red) * frame) / max));
        tempColor.green = Math.floor(sourceColor.green + (((destColor.green - sourceColor.green) * frame) / max));
        tempColor.blue = Math.floor(sourceColor.blue + (((destColor.blue - sourceColor.blue) * frame) / max));

        return (tempColor);
    }

    shiftColors(colors, range, amount) {
        // shift (hard cycle) colors by amount
        let i, j, temp;
        amount = Math.floor(amount);

        for (i = 0; i < amount; i++) {
            temp = colors[range.high];
            for (j = range.high - 1; j >= range.low; j--)
                colors[j + 1] = colors[j];
            colors[range.low] = temp;
        } // i loop
    }

    cycle(sourceColors, timeNow, speedAdjust) {
        // cycle all animated color ranges in palette based on timestamp
        let i;
        let cycleSize, cycleRate;
        let cycleAmount;

        this.copyColors(sourceColors, this.colors);

        if (Palette.ENABLE_CYCLING) {
            for (i = 0; i < this.numCycles; i++) {
                let cycle = this.cycles[i];
                if (cycle.rate) {
                    cycleSize = (cycle.high - cycle.low) + 1;
                    cycleRate = cycle.rate / Math.floor(Palette.CYCLE_SPEED / speedAdjust);

                    if (cycle.reverse < 3) {
                        // standard cycle
                        cycleAmount = Palette.DFLOAT_MOD((timeNow / (1000 / cycleRate)), cycleSize);
                    } else if (cycle.reverse === 3) {
                        // ping-pong
                        cycleAmount = Palette.DFLOAT_MOD((timeNow / (1000 / cycleRate)), cycleSize * 2);
                        if (cycleAmount >= cycleSize) cycleAmount = (cycleSize * 2) - cycleAmount;
                    } else if (cycle.reverse < 6) {
                        // sine wave
                        cycleAmount = Palette.DFLOAT_MOD((timeNow / (1000 / cycleRate)), cycleSize);
                        cycleAmount = Math.sin((cycleAmount * 3.1415926 * 2) / cycleSize) + 1;
                        if (cycle.reverse === 4) cycleAmount *= (cycleSize / 4);
                        else if (cycle.reverse === 5) cycleAmount *= (cycleSize / 2);
                    }

                    if (cycle.reverse === 2) this.reverseColors(this.colors, cycle);

                    this.shiftColors(this.colors, cycle, cycleAmount);

                    if (cycle.reverse === 2) this.reverseColors(this.colors, cycle);

                    cycle.cycleAmount = cycleAmount;
                } // active cycle
            } // i loop
        }
    }

    fade(destPalette, frame, max) {
        // fade entire palette to another, by adjustable amount
        let idx;

        for (idx = 0; idx < this.numColors; idx++)
            this.colors[idx] = this.fadeColor(this.colors[idx], destPalette.colors[idx], frame, max);
    }

    burnOut(frame, max) {
        // burn colors towards black
        let idx, color, amount = Math.floor(255 * (frame / max));

        for (idx = 0; idx < this.numColors; idx++) {
            color = this.colors[idx];
            color.red -= amount;
            if (color.red < 0) color.red = 0;
            color.green -= amount;
            if (color.green < 0) color.green = 0;
            color.blue -= amount;
            if (color.blue < 0) color.blue = 0;
        }
    }

    getRawTransformedColors() {
        // return transformed colors as array of 32-bit ints
        let clrs = [];
        for (let idx = 0, len = this.colors.length; idx < len; idx++) {
            let color = this.colors[idx];
            clrs[idx] = [color.red, color.green, color.blue];
            // clrs[idx] = (color.blue) + (color.green << 8) + (color.red << 16);
        }
        return clrs;
    }
}

class Bitmap {
    constructor(img) {
        this.width = 0;
        this.height = 0;
        this.pixels = null;
        this.palette = null;
        this.drawCount = 0;
        this.optPixels = null;
        // class constructor
        this.width = img.width;
        this.height = img.height;
        this.palette = new Palette(img.colors, img.cycles);
        this.pixels = img.pixels;
    }

    optimize() {
        // prepare bitmap for optimized rendering (only refresh pixels that changed)
        let optColors = [];
        for (let idx = 0; idx < 256; idx++) optColors[idx] = 0;

        // mark animated colors in palette
        const cycles = this.palette.cycles;
        for (let idx = 0, len = cycles.length; idx < len; idx++) {
            let cycle = cycles[idx];
            if (cycle.rate) {
                // cycle is animated
                for (let idy = cycle.low; idy <= cycle.high; idy++) {
                    optColors[idy] = 1;
                }
            }
        }
        this.optColors = optColors;

        // create array of pixel offsets which are animated
        const optPixels = this.optPixels = [];
        const pixels = this.pixels;
        let j = 0;
        let i = 0;
        let x, y;
        const xmax = this.width, ymax = this.height;

        for (y = 0; y < ymax; y++) {
            for (x = 0; x < xmax; x++) {
                if (optColors[pixels[j]]) optPixels[i++] = j;
                j++;
            } // x loop
        } // y loop
    }

    clear(imageData) {
        // clear all pixels to white
        const data = imageData.data;
        let i = 0;
        let x, y;
        const xmax = this.width, ymax = this.height;

        for (y = 0; y < ymax; y++) {
            for (x = 0; x < xmax; x++) {
                data[i] = 255; // red
                data[i + 1] = 255; // green
                data[i + 2] = 255; // blue
                data[i + 3] = 255; // alpha
                i += 4;
            }
        }
    }

    render(imageData, optimize) {
        // render pixels into canvas imageData object
        const colors = this.palette.getRawTransformedColors();
        let data = imageData.data;
        const pixels = this.pixels;

        if (optimize && this.drawCount && this.optPixels) {
            // only redraw pixels that are part of animated cycles
            const optPixels = this.optPixels;
            let i, j, clr;

            for (let idx = 0, len = optPixels.length; idx < len; idx++) {
                j = optPixels[idx];
                clr = colors[pixels[j]];
                i = j * 4;

                data[i] = clr[0]; // red
                data[i + 1] = clr[1]; // green
                data[i + 2] = clr[2]; // blue
                // data[i] = (clr & 0xff0000) >> 16;
                // data[i+1] = (clr & 0x00ff00) >> 8;
                // data[i+2] = (clr & 0x0000ff);

                // data[i + 3] = 255; // alpha
            }
        } else {
            // draw every single pixel
            let i = 0;
            let j = 0;
            let x, y, clr;
            const xmax = this.width, ymax = this.height;

            for (y = 0; y < ymax; y++) {
                for (x = 0; x < xmax; x++) {
                    clr = colors[pixels[j]];

                    data[i] = clr[0]; // red
                    data[i + 1] = clr[1]; // green
                    data[i + 2] = clr[2]; // blue
                    // data[i] = (clr & 0xff0000) >> 16;
                    // data[i+1] = (clr & 0x00ff00) >> 8;
                    // data[i+2] = (clr & 0x0000ff);

                    // data[i + 3] = 255; // alpha

                    i += 4;
                    j++;
                }
            }
        }

        this.drawCount++;
    }
}


let TweenManager = {
    _tweens: {},
    _nextId: 1,

    add: function (_args) {
        // add new tween to table
        let _tween = new Tween(_args);
        this._tweens[this._nextId] = _tween;
        _tween.id = this._nextId;
        this._nextId++;
        return _tween;
    },

    logic: function (clock) {
        // update tweens
        for (let _id in this._tweens) {
            let _tween = this._tweens[_id];
            _tween.logic(clock);
            if (_tween.destroyed) delete this._tweens[_id];
        }
    }
};
TweenManager.tween = TweenManager.add;

// Tween Object
function Tween(_args) {
    // create new tween
    // args should contain:
    //	target: target object
    //	duration: length of animation in logic frames
    //	mode: EaseIn, EaseOut, EaseInOut (omit or empty string for linear)
    //	algorithm: Quadtaric, etc.
    //	properties: { x: {start:0, end:150}, y: {start:0, end:250, filter:Math.floor} }
    if (!_args.algorithm && _args.algo) {
        _args.algorithm = _args.algo;
        delete _args.algo;
    }
    if (!_args.properties && _args.props) {
        _args.properties = _args.props;
        delete _args.props;
    }

    for (let _key in _args) this[_key] = _args[_key];

    // linear shortcut

    this.require('target', 'duration', 'properties');
    // if (typeof(this.target) != 'object') return alert("Tween: Target is not an object");
    if (typeof (this.duration) != 'number') return alert("Tween: Duration is not a number");
    if (typeof (this.properties) != 'object') return alert("Tween: Properties is not an object");

    // setup properties
    for (let _key in this.properties) {
        let _prop = this.properties[_key];
        if (typeof (_prop) == 'number') _prop = this.properties[_key] = {end: _prop};
        if (typeof (_prop) != 'object') return alert("Tween: Property " + _key + " is not the correct format");
        if (typeof (_prop.start) == 'undefined') _prop.start = this.target[_key];

        if (_prop.start.toString().match(/^([\d.]+)([a-zA-Z]+)$/) && !_prop.suffix) {
            _prop.start = RegExp.$1;
            _prop.suffix = RegExp.$3;
            _prop.end = _prop.end.toString().replace(/[^\d.]+$/, '');
        }
        if ((typeof (_prop.start) != 'number') && _prop.start.toString().match(/^\d+\.\d+$/)) {
            _prop.start = parseFloat(_prop.start);
        } else if ((typeof (_prop.start) != 'number') && _prop.start.toString().match(/^\d+$/)) {
            _prop.start = parseInt(_prop.start, 10);
        }

        if ((typeof (_prop.end) != 'number') && _prop.end.toString().match(/^\d+\.\d+$/)) {
            _prop.end = parseFloat(_prop.end);
        } else if ((typeof (_prop.end) != 'number') && _prop.end.toString().match(/^\d+$/)) {
            _prop.end = parseInt(_prop.end, 10);
        }

        if (typeof (_prop.start) != 'number') return alert("Tween: Property " + _key + ": start is not a number");
        if (typeof (_prop.end) != 'number') return alert("Tween: Property " + _key + ": end is not a number");
        if (_prop.filter && (typeof (_prop.filter) != 'function')) return alert("Tween: Property " + _key + ": filter is not a function");
    }
}

Tween.prototype.destroyed = false;
Tween.prototype.delay = 0;

Tween.prototype.require = function () {
    // make sure required class members exist
    for (let _idx = 0, _len = arguments.length; _idx < _len; _idx++) {
        if (typeof (this[arguments[_idx]]) == 'undefined') {
            return alert("Tween: Missing required parameter: " + arguments[_idx]);
        }
    }
    return true;
};

Tween.prototype.logic = function (clock) {
    // abort if our target is destroyed
    // (and don't call onTweenComplete)
    if (this.target.destroyed) {
        this.destroyed = true;
        return;
    }
    if (this.delay > 0) {
        this.delay--;
        if (this.delay <= 0) this.start = clock;
        else return;
    }
    if (!this.start) this.start = clock;

    // calculate current progress
    this.amount = (clock - this.start) / this.duration;
    if (this.amount >= 1.0) {
        this.amount = 1.0;
        this.destroyed = true;
    }

    // animate obj properties
    // for (let _key in this.properties) {
    //     let _prop = this.properties[_key];
    //     let _value = _prop.start + (ease(this.amount, this.mode, this.algorithm) * (_prop.end - _prop.start));
    //     if (_prop.filter) _value = _prop.filter(_value);
    //
    //     this.target[_key] = _prop.suffix ? ('' + _value + _prop.suffix) : _value;
    // }

    // notify object that things are happening to it
    if (this.onTweenUpdate) this.onTweenUpdate(this);
    if (this.target.onTweenUpdate) this.target.onTweenUpdate(this);

    if (this.destroyed) {
        if (this.onTweenComplete) this.onTweenComplete(this);
        if (this.target.onTweenComplete) this.target.onTweenComplete(this);
    }
};

//
// Easing functions
//

// function ease(_amount, _mode, _algo) {
//     return EaseModes[_mode](_amount, _algo);
// }

let FrameCount = {
    current: 0,
    average: 0,
    frameCount: 0,
    lastSecond: 0,
    startTime: 0,
    totalFrames: 0,
    ie: !!navigator.userAgent.match(/MSIE/),
    visible: false,

    _now_epoch: function () {
        // return current date/time in hi-res epoch seconds
        let _mydate = new Date();
        return _mydate.getTime() / 1000;
    },

    count: function () {
        // advance one frame
        let _now = this._now_epoch();
        let _int_now = parseInt(_now, 10);
        let result = false;

        if (_int_now !== this.lastSecond) {
            result = true;
            this.totalFrames += this.frameCount;
            if (!this.startTime) this.startTime = _int_now;
            if (_int_now > this.startTime) this.average = this.totalFrames / (_int_now - this.startTime);
            else this.average = this.frameCount;

            this.current = this.frameCount;
            this.frameCount = 0;
            this.lastSecond = _int_now;
        }
        this.frameCount++;

        return result;
    }

};

export class LivingWorld {
    constructor() {
        this.CanvasCycle = {
            ctx: null,
            imageData: null,
            clock: 0,
            inGame: false,
            bmp: null,
            globalTimeStart: (new Date()).getTime(),
            timeOffset: 0,
            inited: false,
            optTween: null,
            winSize: null,
            globalBrightness: 1.0,
            lastBrightness: 0,
            sceneIdx: 0,
            highlightColor: -1,
            defaultMaxVolume: 0.5,

            TL_WIDTH: 80,
            TL_MARGIN: 15,
            OPT_WIDTH: 150,
            OPT_MARGIN: 15,

            settings: {
                speedAdjust: 1.0,
                initialSceneIdx: 0
            },

            init: function (scene) {
                // called when DOM is ready
                // if (!this.inited) {
                    this.inited = true;

                    // start synced to local time
                    let now = new Date();
                    this.timeOffset = (now.getHours() * 3600) + (now.getMinutes() * 60) + now.getSeconds();

                    // load initial scene
                    this.sceneIdx = this.settings.initialSceneIdx;
                    // this.loadScene(this.settings.initialSceneIdx);
                    this.initScene(scene)
                // }
            },

            initScene: function (scene) {
                // initialize, receive image data from server
                this.initPalettes(scene.palettes);
                this.initTimeline(scene.timeline);

                // force a full palette and pixel refresh for first frame
                this.oldTimeOffset = -1;

                // create an intermediate palette that will hold the time-of-day colors
                this.todPalette = new Palette(scene.base.colors, scene.base.cycles);

                // process base scene image
                this.bmp = new Bitmap(scene.base);
                this.bmp.optimize();

                let canvas = $('df-intro-canvas');
                if (!canvas.getContext) return; // no canvas support

                if (!this.ctx) this.ctx = canvas.getContext('2d');
                this.ctx.clearRect(0, 0, this.bmp.width, this.bmp.height);
                this.ctx.fillStyle = "rgb(0,0,0)";
                this.ctx.fillRect(0, 0, this.bmp.width, this.bmp.height);

                if (!this.imageData) {
                    if (this.ctx.createImageData) {
                        this.imageData = this.ctx.createImageData(this.bmp.width, this.bmp.height);
                    } else if (this.ctx.getImageData) {
                        this.imageData = this.ctx.getImageData(0, 0, this.bmp.width, this.bmp.height);
                    } else return; // no canvas data support
                }
                this.bmp.clear(this.imageData);

                this.globalBrightness = 1.0;
                this.run();
            },

            initPalettes: function (pals) {
                // create palette objects for each raw time-based palette
                let scene = scenes[this.sceneIdx];

                this.palettes = {};
                for (let key in pals) {
                    let pal = pals[key];

                    if (scene.remap) {
                        for (let idx in scene.remap) {
                            pal.colors[idx][0] = scene.remap[idx][0];
                            pal.colors[idx][1] = scene.remap[idx][1];
                            pal.colors[idx][2] = scene.remap[idx][2];
                        }
                    }

                    let palette = this.palettes[key] = new Palette(pal.colors, pal.cycles);
                    palette.copyColors(palette.baseColors, palette.colors);
                }
            },

            initTimeline: function (entries) {
                // create timeline with pointers to each palette
                this.timeline = {};
                for (let offset in entries) {
                    let palette = this.palettes[entries[offset]];
                    if (!palette) return alert("ERROR: Could not locate palette for timeline entry: " + entries[offset]);
                    this.timeline[offset] = palette;
                }
            },

            run: function () {
                // start main loop
                if (!this.inGame) {
                    this.inGame = true;
                    this.animate();
                }
            },

            stop: function () {
                // stop main loop
                this.inGame = false;
            },

            animate: function () {
                // animate one frame. and schedule next
                if (this.inGame) {

                    let optimize = true;
                    let newSec = FrameCount.count();

                    if (newSec && !this.tl_mouseDown) {
                        // advance time
                        this.timeOffset++;
                        if (this.timeOffset >= 86400) this.timeOffset = 0;
                    }

                    if (this.timeOffset != this.oldTimeOffset) {
                        // calculate time-of-day base colors
                        this.setTimeOfDayPalette();
                        optimize = false;
                    }
                    if (this.lastBrightness != this.globalBrightness) optimize = false;
                    if (this.highlightColor != this.lastHighlightColor) optimize = false;

                    // cycle palette
                    this.bmp.palette.cycle(this.bmp.palette.baseColors, this.getTickCount(), this.settings.speedAdjust);

                    if (this.highlightColor > -1) {
                        this.bmp.palette.colors[this.highlightColor] = new Color(0, 0, 0);
                    }
                    if (this.globalBrightness < 1.0) {
                        this.bmp.palette.burnOut(1.0 - this.globalBrightness, 1.0);
                    }

                    // render pixels
                    this.bmp.render(this.imageData, optimize);
                    this.ctx.putImageData(this.imageData, 0, 0);

                    this.lastBrightness = this.globalBrightness;
                    this.lastHighlightColor = this.highlightColor;
                    this.oldTimeOffset = this.timeOffset;

                    TweenManager.logic(this.clock);
                    this.clock++;

                    if (this.inGame) {
                        requestAnimationFrame(() => this.animate());
                    }
                }
            },

            setTimeOfDayPalette: function () {
                // fade palette to proper time-of-day

                // locate nearest timeline palette before, and after current time
                // auto-wrap to find nearest out-of-bounds events (i.e. tomorrow and yesterday)
                let before = {
                    palette: null,
                    dist: 86400,
                    offset: 0
                };
                for (let offset in this.timeline) {
                    if ((offset <= this.timeOffset) && ((this.timeOffset - offset) < before.dist)) {
                        before.dist = this.timeOffset - offset;
                        before.palette = this.timeline[offset];
                        before.offset = offset;
                    }
                }
                if (!before.palette) {
                    // no palette found, so wrap around and grab one with highest offset
                    let temp = 0;
                    for (let offset in this.timeline) {
                        if (offset > temp) temp = offset;
                    }
                    before.palette = this.timeline[temp];
                    before.offset = temp - 86400; // adjust timestamp for day before
                }

                let after = {
                    palette: null,
                    dist: 86400,
                    offset: 0
                };
                for (let offset in this.timeline) {
                    if ((offset >= this.timeOffset) && ((offset - this.timeOffset) < after.dist)) {
                        after.dist = offset - this.timeOffset;
                        after.palette = this.timeline[offset];
                        after.offset = offset;
                    }
                }
                if (!after.palette) {
                    // no palette found, so wrap around and grab one with lowest offset
                    let temp = 86400;
                    for (let offset in this.timeline) {
                        if (offset < temp) temp = offset;
                    }
                    after.palette = this.timeline[temp];
                    after.offset = temp + 86400; // adjust timestamp for day after
                }

                // copy the 'before' palette colors into our intermediate palette
                this.todPalette.copyColors(before.palette.baseColors, this.todPalette.colors);

                // now, fade to the 'after' palette, but calculate the correct 'tween' time
                this.todPalette.fade(after.palette, this.timeOffset - before.offset, after.offset - before.offset);

                // finally, copy the final colors back to the bitmap palette for cycling and rendering
                this.bmp.palette.importColors(this.todPalette.colors);
            },

            getTickCount: function () {
                // milliseconds since page load
                return Math.floor((new Date()).getTime() - this.globalTimeStart);
            }
        };
    }

}





