export class Stars {
  constructor() {
    this.timestamp = null;
    this.lastMouseY = null;
    this.speedY = 0;
    this.canvas = document.getElementById('df-stars');
    this.ctx = this.canvas.getContext('2d');
    this.ctx.fillStyle = 'black';
    this.ctx.rect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fill();
    this.starList = [];
    this.timeoutId = null;
  }

  mousemoveHandler = event => {
    if (null === this.timeoutId) {
      this.timeoutId = setTimeout(() => {
        let screenY = event.changedTouches ? event.changedTouches[0].screenY : event.screenY;
        if (this.timestamp === null) {
          this.timestamp = Date.now();
          this.lastMouseY = screenY;

          return;
        }
        let now = Date.now();
        let dt = now - this.timestamp;
        let dy = screenY - this.lastMouseY;
        this.speedY = Math.round(dy / dt * 100);
        this.timestamp = now;
        this.lastMouseY = screenY;
      }, 1);
    } else {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  starField() {
    for (let i = 0; i < 60; i++) {
      let x = Math.round(Math.random() * 200);
      let y = Math.round(Math.random() * 131);
      let star = {
        x_real: x,
        y_real: y,
        x: x,
        y: y,
        vx: Math.random() * 10
      };
      this.starList.push(star);
    }
  }

  run = () => {
    this.ctx.fillStyle = 'black';
    this.ctx.rect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fill();
    let star;
    for (let i = 0, j = this.starList.length; i < j; i++) {
      star = this.starList[i];
      star.x_real = star.x_real - star.vx;
      star.x = Math.round(star.x_real);
      star.y_real = star.y_real + star.vx * (this.speedY / 100);
      star.y = Math.round(star.y_real);
      if (star.x < 0) {
        star.x_real = 200;
        star.y_real = Math.round(Math.random() * 131);
        star.vx = Math.random() * 10;
      }
      this.ctx.fillStyle = 'white';
      this.ctx.beginPath();
      this.ctx.rect(star.x, star.y, 1, 1);
      this.ctx.closePath();
      this.ctx.fill();
    }
    let hadBoth = false;
    if (this.speedY > 0) {
      this.speedY = this.speedY - 10;
      hadBoth = true;
    }
    if (this.speedY < 0) {
      this.speedY = this.speedY + 10;
      if (hadBoth) {
        this.speedY = 0;
      }
    }
    window.requestAnimationFrame(this.run);
  }
}
