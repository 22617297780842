import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';
import Intro from "@/components/Intro";
import Amiga from "@/components/Amiga";
import Writer from "@/components/Writer";
import Master from "@/components/Master";
import Desk from "@/components/Desk";
import Imprint from "@/components/Imprint";

Vue.use(VueRouter)
Vue.use(RouterPrefetch)

const routes = [
  {
    path: '/',
    name: 'Intro',
    component: Intro,
    meta: {transitionName: 'fade'},
  },
  {
    path: '/home',
    name: 'Desk',
    component: Desk,
    meta: {transitionName: 'random'},
  },
  {
    path: '/contact',
    name: 'Amiga',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '@/components/Home')
    component: Amiga,
    meta: {transitionName: 'random'},
  },
  {
    path: '/projects',
    name: 'Writer',
    component: Writer,
    meta: {transitionName: 'random'},
  },
  {
    path: '/master',
    name: 'Master',
    component: Master,
    meta: {transitionName: 'random'},
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: {transitionName: 'fade'},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
