<template>
  <div class="df-container">
    <div class="df-frame">
      <div class="courtain"></div>
      <canvas class="df-canvas" width="320" height="180"></canvas>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FramePage"
}
</script>