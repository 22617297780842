<template>
  <div>
    <div class="df-nav">
      <navigation-icon-link to="/home" id="df-nav-back" :class="{'df-nav-hide': !backRoute}"
                            classes="df-shadow-link df-nav-link df-nav-24"/>
    </div>
    <div class="df-imprint-container">
      <router-link id="df-nav-imprint" :class="{'df-nav-hide': !imprintRoute, 'df-shadow-link': true}" to="/imprint">
        <div id="df-imprint-image" class="df-image"/>
        <div id="df-imprint-glow" class="df-image"/>
        <span>Imprint</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import NavigationIconLink from "@/components/partial/NavigationIconLink";

export default {
  name: 'NavigationScreen',
  components: {NavigationIconLink},
  props: ['backRoute', 'imprintRoute'],
}
</script>

<style lang="scss">
@import '../assets/scss/nav';
</style>
