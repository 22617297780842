import {getSceneV08} from "@/assets/js/intro/scene_V08";

export function getRandomScene() {
    // let randomScene = Math.random();
    // if (randomScene < 0.2) {
    //     return getSceneV08CLDY();
    // }
    // if (randomScene > 0.8) {
    //     return getSceneV08RAIN();
    // }
    return getSceneV08();
}
