<template>
  <transition
    :name="transitionName"
    :mode="transitionMode"
    :enter-active-class="transitionEnterActiveClass"
  >
    <slot/>
  </transition>
</template>

<script>
const DEFAULT_TRANSITION = 'fade';
const DEFAULT_TRANSITION_MODE = 'out-in';
const randomTransition = [
  'slide',
  'slide-reverse',
  'zoom',
  'zoom-reverse'
];
export default {
  name: 'TransitionPage',
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION,
      transitionMode: DEFAULT_TRANSITION_MODE,
      transitionEnterActiveClass: '',
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = from.meta.transitionName || DEFAULT_TRANSITION;

      if ('fade' === to.meta.transitionName) {
        transitionName = 'fade';
      }

      if (transitionName.startsWith('random')) {
        transitionName = randomTransition[Math.floor(Math.random() * randomTransition.length)];
      }

      this.transitionName = transitionName;
      this.transitionMode = DEFAULT_TRANSITION_MODE;
      this.transitionEnterActiveClass = `${transitionName}-enter-active`;

      if (transitionName.startsWith('slide') || transitionName.startsWith('zoom')) {
        this.transitionMode = null;
      }
      next();
    });
  }
};
</script>

<style lang="scss">
@import '../../assets/scss/transition';
</style>