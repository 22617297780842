<template>
  <frame-page>
    <canvas id="df-intro-canvas" width="320" height="180"/>
    <div class="df-content">
      <h1 class="df-shadow-text" id="df-intro-title">DEYNFISK</h1>
      <router-link to="/home" class="df-shadow-link" id="df-intro-continue">
        <span>click here to continue</span>
      </router-link>
      <div id="df-intro-continue-image" class="df-image df-blink"/>
      <div id="df-intro-continue-glow" class="df-image"/>
      <div id="df-intro-deynfisk" class="df-image"/>
    </div>
  </frame-page>
</template>

<style lang="scss">
@import '../assets/scss/intro';
</style>

<script>
import {LivingWorld} from "@/assets/js/intro/living_worlds";
import {getRandomScene} from "@/assets/js/intro/scenes";
import FramePage from "@/components/FramePage";

let dateTimeInterval = null;
// let keyupHandler = () => {
// };

export default {
  name: 'IntroScreen',
  components: {FramePage},
  title: null,
  mounted() {
    this.$emit('nav-back', null);
    let CC = new LivingWorld().CanvasCycle;
    CC.init(getRandomScene());
    CC.run();
    let offset = 0
    dateTimeInterval = setInterval(function () {
      let now = new Date();
      offset += 50;
      CC.timeOffset = (offset + (now.getHours() * 3600) + (now.getMinutes() * 60) + now.getSeconds()) % 86400;
      if (
        CC.timeOffset < 1500
        || (CC.timeOffset > 5600 && CC.timeOffset < 17200)
        || (CC.timeOffset > 32000 && CC.timeOffset < 42900)
        || (CC.timeOffset > 52000 && CC.timeOffset < 57000)
        || CC.timeOffset > 78300
      ) {
        offset += 250;
      }
    }, 15);
  },
  destroyed() {
    clearInterval(dateTimeInterval);
  }
}
</script>
