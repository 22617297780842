<template>
  <div class="df-project-container">
    <div class="df-project-flex">
      <div class="df-project-image">
        <div class="df-project-left-frame">
          <img class="df-image" src="@/assets/img/writer/studio_kubik.png" alt="project studio-kubik preview">
        </div>
      </div>
      <div class="df-project-text">
        <div class="df-project-right-frame">
          <h2>Studio-Kubik</h2>
          <p>
            <ExternalLink link="http://studio-kubik.de">studio-kubik.de</ExternalLink>
            <br><br>
            A young architecture office in the core of berlin. Their planning ranges from multi-building complexes to
            kindergartens and even interior design is one of their strongsuits.
          </p>
        </div>
      </div>
    </div>
    <p>
      Tasks:<br>
    </p>
    <ul>
      <li>
        Develop a custom responsive
        <external-link link="https://wordpress.org/">Wordpress.org</external-link> theme, based on the
        <external-link link="https://getbootstrap.com/">bootstrap</external-link> framework
      </li>
      <li>
        Create a mobile-first gallery by implementing the
        <external-link link="https://swiperjs.com/">swiper.js</external-link> package
      </li>
      <li>
        Enable the use of multiple languages (german and english) with the
        <external-link link="https://wpglobus.com/">WPGlobus</external-link> plugin
      </li>
      <li>
        Animations for fade-ins and -outs based on category selection and for a short introduction
      </li>
    </ul>
  </div>
</template>

<script>
import ExternalLink from "@/components/partial/ExternalLink";

export default {
  name: 'StudioKubik',
  components: {ExternalLink},
  mounted() {
  },
  destroyed() {
  }
}
</script>
