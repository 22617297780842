<template>
  <div class="df-project-container">
    <h2>Revolver Promotion</h2>
    <p>
      <external-link link="https://www.revolverpromotion.com/">revolverpromotion.com</external-link>
      <br><br>
      An agency for TV, online and radio promotion since 2004. The website showcases the latest roster and news about
      upcoming releases. Its newest feature is the inhouse shop! Based on
      <external-link link="https://wordpress.org/">Wordpress.org</external-link>
      <br>Tasks were:
    </p>
    <ul>
      <li>
        Implement the
        <external-link link="https://themeforest.net/item/oldpaper-ultimate-magazine-blog-theme/7431822">
          Old Paper
        </external-link>
        theme
      </li>
      <li>
        Setup a self hosted shop platform with the
        <external-link link="https://woocommerce.com/">
          WooCommerce
        </external-link>
        plugin, while preserving german guidelines with the
        <external-link link="https://de.wordpress.org/plugins/woocommerce-germanized/">germanized</external-link>
        plugin
      </li>
    </ul>
    <h2>Dimension14</h2>
    <p>
      <external-link link="https://dimension14.de/">dimension14.de</external-link>
      <br><br>
      A showcase website for temporary and site-specific art in the south of Berlin.
      <br>Tasks were:
    </p>
    <ul>
      <li>
        Custom adjustments of the existing
        <external-link link="https://wordpress.org/">Wordpress.org</external-link>
        theme
        <external-link link="https://avada.theme-fusion.com/">Avada</external-link>
      </li>
      <li>Insertion of new data through the CMS</li>
    </ul>
    <h2>Bundesdruckerei</h2>
    <p>
      CONFIDENTIAL
      <br>Tasks were:
    </p>
    <ul>
      <li>
        Co-develop an
        <external-link link="https://www.android.com/intl/de_de/">android</external-link>
        app, including a barcode scan functionality
      </li>
    </ul>
  </div>
</template>

<script>
import ExternalLink from "@/components/partial/ExternalLink";

export default {
  name: 'OtherProjects',
  components: {ExternalLink},
  mounted() {
  },
  destroyed() {
  }
}
</script>
