export class StarsAnimation {
  constructor() {
    this.canvas = document.getElementById('df-desk-stars');
    this.ctx = this.canvas.getContext('2d');
    this.frameCount = 15;
    this.canvas.width = 29;
    this.canvas.height = 35;
    this.index = 1;
  }

  getStarFrame(index) {
    return `static/desk/stars/star_${index.toString().padStart(2, '0')}.png`;
  }

  animate = () => {
    if (this.frameCount + 1 === this.index) {
      this.index = 1;
    }
    const img = new Image()
    img.src = this.getStarFrame(this.index);
    img.onload = () => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.drawImage(img, 0, 0);
    }
    setTimeout(() => {
      ++this.index;
      window.requestAnimationFrame(this.animate);
    }, 50)
  }
}

