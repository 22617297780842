<template>
  <div class="df-project-container">
    <div class="df-project-flex">
      <div class="df-project-image">
        <div class="df-project-left-frame">
          <img class="df-image" src="@/assets/img/writer/sue_hayward.png" alt="project sue hayward preview">
        </div>
      </div>
      <div class="df-project-text">
        <div class="df-project-right-frame">
          <h2>Sue Hayward</h2>
          <p>
            <ExternalLink link="https://suehayward.de">suehayward.de</ExternalLink>
            <br><br>
            A display website of Pictures, Sculptures installations and exhibitions made by artist Sue Hayward,
            located in berlin.
          </p>
        </div>
      </div>
    </div>
    <p>Tasks:</p>
    <ul>
      <li>Modernize an existing website into a responsive custom theme for Wordpress</li>
      <li>
        Core concept: Implementation of a
        <external-link link="http://miromannino.github.io/Justified-Gallery/">Justified Gallery</external-link>
      </li>
      <li>
        Enable multilingualism (english, german) through the
        <external-link link="https://wpglobus.com/">WPGlobus</external-link> plugin</li>
      <li>
        Showcase selected images based on the
        <external-link link="https://photoswipe.com/">photoswipe.js</external-link> library, including embedded videos
      </li>
    </ul>
  </div>
</template>

<script>
import ExternalLink from "@/components/partial/ExternalLink";

export default {
  name: 'SueHayward',
  components: {ExternalLink},
  mounted() {
  },
  destroyed() {
  }
}
</script>
