<template>
  <frame-page>
    <div id="df-paper-frame">
      <Projects/>
    </div>
    <div id="df-writer-shadow" class="df-image"></div>
    <div id="df-writer-machine" class="df-image"></div>
    <div id="df-writer-roll" class="df-image"></div>
  </frame-page>
</template>

<style lang="scss">
@import '../assets/scss/writer';
</style>

<script>
import Projects from "@/components/projects/Projects";
import FramePage from "@/components/FramePage";

let paperFrame;
let isScrolling;
let scrollingImage;
let scrollEvent;

export default {
  name: 'WriterScreen',
  components: {FramePage, Projects},
  title: 'Projects',
  mounted() {
    this.$emit('nav-back', '/home');
    paperFrame = document.getElementById('df-paper-frame');
    scrollingImage = document.getElementById('df-writer-roll');
    scrollEvent = () => {
      scrollingImage.classList.add('scrolling');
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        scrollingImage.classList.remove('scrolling');
      });
    }
    paperFrame.addEventListener('scroll', scrollEvent);
  },
  destroyed() {
    paperFrame.removeEventListener('scroll', scrollEvent);
  }
}
</script>
